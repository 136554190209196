<template>
  <ul class="chat__messages" :style="{ marginBottom: marginBottom + 'px' }">
    <Message v-for="item in messages" v-bind:message="item" />
  </ul>
</template>

<script>
import Message from './Message'

export default {
  name: 'MessageList',
  props: ['messages'],
  components: {
    Message
  },
  data: function () {
    return {
      marginBottom: 0
    }
  }
}
</script>

<style scoped>
  .chat__messages {
    max-width: 560px;
  }
  ul {
    /* padding: 10px; */
    /* flex: 1 1 auto; */
    /* overflow-y: auto; */
    /* min-height: 0; */
  }
</style>
