<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">

                <spinner v-show="loading"></spinner>

                <article class="tile is-child box" v-if="!loading && item">

                  <h1 class="title">Чат c <small>{{ item.patient_user.full_name }}</small></h1>
                    <h2 class="subtitle">Pid: {{ item.medialog_patient_id }}</h2>

                    <div id="chat">
                      <MessageList :messages="item.messages" ref="messages" />
                      <MessageInput :onSend="onSend" :saving="saving"/>
                    </div>

                </article>
            </div>
        </div>
        <div :style="{ height: spacerHeight + 'px' }"></div>
    </div>
</template>

<script>
import chatService from '../../services/chats'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import MessageList from './MessageList'
import MessageInput from './MessageInput'
import { CHAT_INPUT_RESIZED } from './../../events'
import EventBus from './../../event-bus'

export default {
  mixins: [createOrUpdateViewMixin(chatService, {
    message: false
  })],
  components: {
    MessageList,
    MessageInput
  },
  data: function () {
    return {
      saving: false,
      intervalId: null,
      unwatch: null,
      spacerHeight: 0
    }
  },
  methods: {
    onSend: function (message) {
      if (this.saving) return
      this.saving = true

      let errorHandler = (error) => {
        if (error) {
          this.saving = false
        }
      }

      chatService.addMessage(this.$route.params.id, { message }).then(
        response => {
          this.fetch().then(() => { this.saving = false }, errorHandler)
        },
        errorHandler
      )
    }
  },
  mounted: function () {
    EventBus.$on(CHAT_INPUT_RESIZED, ({ height }) => {
      this.spacerHeight = height
    })
    this.unwatch = this.$watch('item.messages', (newVal, oldVal) => {
      if (!newVal || !oldVal || newVal.length !== oldVal.length) {
        this.$nextTick(() => {
          this.$scrollTo(this.$el, 0, {
            offset: this.$el.offsetHeight
          })
        })
      }
    })
    this.intervalId = setInterval(() => this.fetch(true), 5000)
  },
  beforeDestroy: function () {
    clearInterval(this.intervalId)
    if (this.unwatch !== null) {
      this.unwatch()
    }
  }
}
</script>

<style lang="scss" scoped>
  #chat {
    flex: 1;
    display: flex;
    height: 100%;
    flex-direction: column;
  }
</style>
