import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=206f8a89&scoped=true&"
import script from "./Chat.vue?vue&type=script&lang=js&"
export * from "./Chat.vue?vue&type=script&lang=js&"
import style0 from "./Chat.vue?vue&type=style&index=0&id=206f8a89&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206f8a89",
  null
  
)

export default component.exports