<template>
  <li class="chat__message" :class="classes">
    <span><b>{{author}}</b> <small>{{message.datetime | dateTime }}</small></span>
    <p>{{message.message}}</p>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: [
    'message'
  ],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    }),
    classes () {
      let classes = this.wasSentByMe ? 'is-right' : 'is-left'
      return classes
    },
    author () {
      return this.wasSentByMe ? 'Вы' : this.message.sender.full_name
    },
    wasSentByMe () {
      return this.message.sender.uuid === this.currentUser.uuid
    }
  },
  name: 'Message'
}
</script>

<style scoped>
  .chat__message {
    position: relative;
    padding: 10px;
    background: hsl(0, 0%, 96%);
    max-width: 340px;
    border-radius: 5px;
    margin-bottom: 1em;
  }
  .chat__message:before {
    position: absolute;
    content: '';
    display: block;
    border: 0.5em solid;
    border-color: transparent hsl(0, 0%, 96%);
    font-size: 10px;
    width: 0;
    height: 0;
    display: none;
    /* transform: rotate(45deg); */
    /* transform-origin: 50% 50%; */
  }
  .chat__message span {
    font-weight: bold;
  }
  .chat__message small {
    color: #999;
    font-weight: normal;
  }

  .chat__message.is-right {
    margin-right: 0;
    margin-left: auto;
  }
  .chat__message.is-left {
    margin-left: 0;
    margin-right: auto;
  }
  .chat__message.is-right:before {
    top: 50%;
    left: 100%;
    border-width: 0.5em 0 0.5em 1em;
  }
  .chat__message.is-left:before {
    top: 50%;
    margin-top: -0.5em;
    right: 100%;
    border-width: 0.5em 1em 0.5em 0;
  }

  @media screen and (max-width: 919px) {
    .chat__message:before {
      display: block;
    }
  }
</style>
